<template>
  <!-- Projects Table Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ padding: 0 }"
  >
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h6>Últimas Campañas Activas</h6>
          <!-- <p>done this month <span class="text-primary">+40%</span></p> -->
        </a-col>

       <!-- Filtros de  
        <a-col
          :span="24"
          :md="12"
          style="display: flex; align-items: center; justify-content: flex-end"
        >
          <a-radio-group v-model="projectHeaderBtns" size="small">
            <a-radio-button value="all">TODOS</a-radio-button>
            <a-radio-button value="CASHBACK">CASHBACK</a-radio-button>
            <a-radio-button value="ENVIO">ENVIO</a-radio-button>
            <a-radio-button value="REGALOSEGURO">REGALO SEGURO</a-radio-button>
          </a-radio-group>
        </a-col>-->
      </a-row>
    </template>
<!--Coge los datos que se muestran en Ultimas Campañas Activas-->
    <a-table :columns="columns" :data-source="selectedCampanaTypeList" :pagination="false">
      <!-- <a-space
        slot="members"
        slot-scope="members"
        :size="-12"
        class="avatar-chips"
      >
        <template v-for="member in members">
          <a-avatar :key="member" size="small" :src="member" />
        </template>
      </a-space>       -->
   <!---->   <template slot="client" slot-scope="client">
        <h6 class="m-0">
          <!-- <img :src="company.logo" width="25" class="mr-10" /> -->
          {{ client }}
        </h6>
      </template>
      <template slot="company" slot-scope="company">
        <h6 class="m-0">
          <!-- <img :src="company.logo" width="25" class="mr-10" /> -->
          {{ company.name }}
        </h6>
      </template>
      
      <template slot="completion" slot-scope="completion">
        <span class="font-bold text-muted text-sm">{{
           completion
        }}</span>

        
        <!-- <a-progress
          :percent="completion.value ? completion.value : completion"
          :show-info="false"
          size="small"
          :status="completion.status ? completion.status : 'normal'"
        /> -->
      </template>
      <template slot="totalShipments" slot-scope="totalShipments">
        <h6 class="m-0">
          <!-- <img :src="company.logo" width="25" class="mr-10" /> -->
          {{ totalShipments }}
        </h6>
      </template>
      <template slot="totalTransfers" slot-scope="totalTransfers">
        <h6 class="m-0">
          <!-- <img :src="company.logo" width="25" class="mr-10" /> -->
          {{ totalTransfers }}
        </h6>
      </template>
    </a-table>

    <div class="table-upload-btn">
      <a-button @click="cargartodas" type="dashed" block>
        <i class="fa fa-eye"></i>
       
        Ver Todas
      </a-button>
    </div>
  </a-card>
  <!-- / Projects Table Card -->
</template>

<script>
export default {
  props: {
    
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    }
    
  },
  methods:{
    cargartodas(evt){
      this.$router.push({ name: "campanas" });
    }
  },
  data() {
    return {
      // Active button for the "Projects" table's card header radio button group.
      projectHeaderBtns: "all",
      campanaTipoShow: -1
    };
  },
  computed: {
    selectedCampanaTypeList(){
      var dataToShow = [];
      switch (this.projectHeaderBtns){
        case "all":
          this.campanaTipoShow = -1;
          break;
        case "CASHBACK":
          this.campanaTipoShow = 4;
          break;
        case "INSTANTWIN":
          this.campanaTipoShow = 5;
          break;
        case "REGALOSEGURO":
          this.campanaTipoShow = 6;
          break;
        case "ENVIO":
          this.campanaTipoShow = 7;
          break;
      }
      
      dataToShow = this.campanaTipoShow === -1 ? this.allCampanas : this.filteredCampanasByType
      return dataToShow.slice(0,5);
    },

    allCampanas(){
      return this.data;
    },
    filteredCampanasByType(){
      let filteredData = []
      this.data.map(item => {
        if(item.campanaTipoId === this.campanaTipoShow){
          filteredData.push(item);
        }
      });
      return filteredData;
    }
  }
};
</script>