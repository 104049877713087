<template>

	<!-- Active Users Card -->
	<a-card :bordered="false" class="dashboard-bar-chart">
		<chart-bar :height="310" :data="barChartData"></chart-bar>
		<!-- <div class="card-title">
			<h6>Participaciones Últimos 30 Días</h6>
			<h6>Participaciones Últimos 30 Días</h6>
		</div> -->
		<!-- <div class="card-content">
			<p>-</p>
		</div>
		<a-row class="card-footer" type="flex" justify="center" align="top">
			<a-col :span="8">
				<h4>{{this.$store.state.homeModule.list.length}}</h4>
				<span>Campañas</span>
			</a-col>
			<a-col :span="8">
				<h4>{{$store.state.homeModule.list.filter((e) => e.tipo == 4).length}}</h4>
        
				<span>Campañas Cashback</span>
			</a-col>
			<a-col :span="8">
				<h4>{{$store.state.homeModule.list.filter((e) => e.tipo == 7).length}}</h4>
				<span>Campañas Envio</span>
			</a-col>
			
		</a-row> -->
	</a-card>
	<!-- Active Users Card -->

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartBar from '../Charts/ChartBar.vue' ;
	import HomeObject from "../../../store/entities/OZONE/home";
	export default ({
		components: {
			ChartBar,
			
		},
		data() {
			
			return {
				
				// Data for bar chart.
				barChartData: {
					labels: ["Últimos 30 Días "],
					datasets: [{
						label: "Participaciones Últimos 30 Días",
						backgroundColor: '#fff',
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 6,
						data: [this.$store.state.homeModule.pUlt],
						maxBarThickness: 20,
					}, ],
				},
			}
		},
	})

</script>