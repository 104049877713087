<template>

	<a-card :bordered="false" class="dashboard-bar-line header-solid">
		<template #title>
			<h6># Participaciones Anual</h6>			
			<!-- <p>than last year <span class="text-success">+20%</span></p>	 -->
		</template>
		<template #extra>
			<a-badge color="primary" class="badge-dot-primary" text="Participaciones" />
		</template>
		<chart-line :height="310" :data="lineChartData"></chart-line>
	</a-card>

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartLine from '../Charts/ChartLine' ;

	export default ({
		
		components: {
			ChartLine,
		},
		created(){
			
		},
		data() {
			//gráfico participaciones anual
		/*	return {

				// Data for line chart.
				lineChartData: {
					labels: this.$store.state.homeModule.pLabales,
					datasets: [{
						label: "Participaciones",
						tension: 0.4,
						borderWidth: 0,
						pointRadius: 0,
						borderColor: "#040021",
						borderWidth: 3,
						data: this.$store.state.homeModule.pData,
						maxBarThickness: 6

					},],
				},
			}*/
		},
	})

</script>