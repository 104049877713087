<template>

	<!-- Orders History Timeline Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: '12px',}">
		<template #title>
			<h6>Actividad Ozone</h6>			
			<!-- <p>this month <span class="text-success">+20%</span></p>	 -->
		</template>
		<a-timeline pending="Actualizando..." :reverse="timelineReverse">
			<a-timeline-item color="green">
				Campaña PG Creada
				<p>09 JUN 7:20 PM</p>
			</a-timeline-item>
			<a-timeline-item color="red">
				Campaña MasMovil Cancelada
				<p>08 JUN 12:20 PM</p>
			</a-timeline-item>
			<a-timeline-item color="blue">
				Campaña  Tommee Tipppee Finalizada 
				<p>04 JUN 3:10 PM</p>
			</a-timeline-item>
			<a-timeline-item color="gray">
				Transferencias Realizadas
				<p>02 JUN 2:45 PM</p>
			</a-timeline-item>
			<a-timeline-item color="gray">
				Fichero SEPA Generado
				<p>18 MAY 1:30 PM</p>
			</a-timeline-item>
			<a-timeline-item color="green">
				Campaña MasMovil Creada
				<p>14 MAY 3:30 PM</p>
			</a-timeline-item>
			<template #pendingDot> </template>
		</a-timeline>
	</a-card>
	<!-- / Orders History Timeline Card -->

</template>

<script>

	export default ({
		data() {
			return {

				// Whether or not the timeline in "Orders History" card is reversed.
				timelineReverse: false,
			}
		},
	})

</script>