
export default class Home{
    // tabla: [
    //     {
    //         key: "",
    //         company: {
    //           name: "",
    //           logo: "",
    //         },
    //         members: [
    //           "",
    //           "",
    //           "",
    //           "",
    //         ],
    //         budget: "",
    //         client: "",
    //         completion: 0,
    //       },
    // ]
    key:string;
    company:{
        name:"",       
    }
    campanaTipoId: number;
    members:  Array<any> = [];
    completion:string;
    client:string;
    budget:number;
    totalShipments: number;
    totalTransfers: number;

    
   
    
    
   
    
    
    
}