<template>
  <div>
    <canvas ref="chart" :style="{ height: height + 'px' }"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  name: "ChartBar",
  props: {
    data: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default: 310,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    data: {
      handler(newData) {
        if (this.chart) {
          this.chart.data = newData;
          this.chart.update();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const ctx = this.$refs.chart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.data,
        options: {
          layout: {
            padding: {
              top: 30,
              right: 15,
              left: 10,
              bottom: 5,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              // Changed from tooltips to tooltip in v3
              enabled: true,
              mode: "index",
              intersect: false,
            },
          },
          scales: {
            y: {
              grid: {
                display: true,
                color: "rgba(255, 255, 255, .2)",
                borderDash: [6],
                borderDashOffset: 6,
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 1000,
                color: "#fff",
                font: {
                  size: 14,
                  lineHeight: 1.5,
                  weight: "600",
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#fff",
                font: {
                  size: 14,
                  lineHeight: 1.5,
                  weight: "600",
                },
              },
            },
          },
        },
      });
    },
  },
  beforeDestroy() {
    // Para Vue 2
    if (this.chart) {
      this.chart.destroy();
    }
  },
  beforeUnmount() {
    // Para Vue 3 (opcional en tu caso)
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
canvas {
  background-image: linear-gradient(to right, #00369e, #005cfd, #a18dff);
}
</style>
